import { 
  InMemoryCache, HttpLink, ApolloClient 
} from '@apollo/client';
import fetch from 'isomorphic-fetch';

let uri;
if (process.env.NODE_ENV === "production") {
  uri = `https://e02cf0559e3714954.temporary.link/graphql`;
} else {
  uri = `http://localhost:8888/wp-spa/graphql`;
}

export const client = new ApolloClient({ 
  link: new HttpLink({
    uri,
    fetch
  }),
  cache: new InMemoryCache()
});
