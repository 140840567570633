module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://e02cf0559e3714954.temporary.link/graphql","verbose":true,"develop":{"hardCacheMediaFiles":true},"debug":{"graphql":{"writeQueriesToDisk":true}},"type":{"Post":{"limit":5000},"schema":{"requestConcurrency":5,"previewRequestConcurrency":2}},"auth":{"wpcom_pass":"0000"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-chakra-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://e02cf0559e3714954.temporary.link/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","icon":"src/assets/images/apdCrest.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"12f99366caa7d12011b1bd1f9bfc0276"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
