import React, { 
  createContext,useState, useRef,useEffect, useMemo
} from 'react';

const defaultState = {
  scrollTop: null
}

const AppCtx = createContext(defaultState);

const AppProvider = (props) => {
  const {children} = props;

  const [scrollTop, setScrollTop] = useState(null);

  const animationContainer = useRef(null);

  const handleScroll = e => {
    setScrollTop(e.target.documentElement.scrollTop);

  };

  useEffect(() => {  
    //nav animation logic
    const body = document.body;
    if(scrollTop > 70){
      if(!body.classList.contains('solid-nav')){
        body.classList.add('solid-nav');
      }
    } else {
      if(body.classList.contains('solid-nav')){
        body.classList.remove('solid-nav');
      }
    }
    

    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, [scrollTop, animationContainer]);


   // ----------REACT PROVIDER ------------
  const appProvider = useMemo(() => ({
    scrollTop, animationContainer
  }), [
    scrollTop, animationContainer
  ]);

  return(
    <AppCtx.Provider value={appProvider}>
      <div className="provider-root" ref={animationContainer}>
        {children}
      </div>
    </AppCtx.Provider>
  )

}

export default AppCtx;
export {AppProvider}