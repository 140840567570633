import React from "react";
import * as Apollo from "@apollo/client";
import * as ApolloClient from './src/context/ApolloCtx';
import * as Provider from './src/context/App_Ctx';
const { ApolloProvider } = Apollo;
const { AppProvider } = Provider;
const { client } = ApolloClient;

export const wrapRootElement = ({ element }) =>( 
  <ApolloProvider client={client}>
    <AppProvider>
      {element}
    </AppProvider>
  </ApolloProvider>
);